<template>
  <div class="about">
    <div class="about_content">
      <div class="content_single">
        <div class="tit">联系我们</div>
        <div class="cont">
          <div class="form">
            <div class="form-item">
              <div class="lable">您的名称：</div>
              <div class="value">
                <el-input v-model="name"></el-input>
              </div>
            </div>
            <div class="form-item">
              <div class="lable">联系电话：</div>
              <div class="value">
                <el-input v-model="phone"></el-input>
              </div>
            </div>
            <div class="form-item">
              <div class="lable">月订单量：</div>
              <div class="value">
                <el-input v-model="number"></el-input>
              </div>
            </div>
            <div class="form-item">
              <div class="lable">来源：</div>
              <div class="value">
                <el-select v-model="source" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="form-btn">
              <el-Button @click="submit">提交</el-Button>
            </div>
          </div>
        </div>
      </div>
      <div class="content_single">
        <div class="tit">联系方法</div>
        <div class="contact">
          <div class="item"> 邮箱: contact@bacads.com </div>
          <div class="item"> 地址: 武汉东湖新技术开发区光谷大道3号激光工程设计总部二期研发楼<br />06幢06单元15层5号（K088） </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      phone: "",
      number: "",
      source: "",
      options: [{ label: "百度", value: 1 }, { label: "Google", value: 2 }, { label: "论坛", value: 3 }, { label: "朋友推荐", value: 4 }, { label: "其他", value: 5 }]
    }
  },
  methods: {
    submit() {
      if (!this.name || !this.phone || !this.number || !this.source) return this.$message.error("提交失败")
      this.name = ""
      this.phone = ""
      this.number = ""
      this.source = ""
      this.$message.success("提交成功")
    }
  },
}
</script>



<style scoped lang="scss">
.about_content {
  margin: 0 auto;
  padding: 50px 0;
  width: 1000px;
}

.content_single {
  width: 1000px;
  margin-bottom: 70px;
}

.tit {
  font-size: 42px;
  line-height: 60px;
  font-family: 'MyFont2', sans-serif;
  color: #0B77A9;
  text-align: center;
  margin-bottom: 50px;
}

.cont {
  width: 1000px;
  height: 456px;
  background-color: #E9E9E9;
  border-radius: 27px;
  display: flex;
  align-items: center;
}

.form {
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  padding: 60px;



  .form-item {
    margin: 10px auto;
    width: 550px;
    display: flex;
    align-items: center;

    .lable {
      width: 80px;
      line-height: 18px;
      font-size: 16px;
      font-weight: 700;
      text-align: right;
    }

    .value {
      margin-left: 75px;
      width: 374px;
      height: 47px;
    }
  }

  .form-btn>button {
    background-color: #0B77A9;
    color: white;
    margin-top: 48px;
    border-radius: 5px;
  }
}
.item{
  font-size: 32px;
  line-height: 52px;
  margin-bottom: 30px;
  font-weight: 400;
}

.el-select {
  width: 100%;
}
</style>